import HttpService from "../http/_httpSevices";
import { IListStoriesDatas } from "../interfaces/_intercase-datas";
import { Response } from "../interfaces/_response";

class listTours {
  http: HttpService;
  actions = {
    list: 'list_tours',
  };
  datas: IListStoriesDatas[] = [];
  classes = {
    list : '.js-list-tours',
    append : '.js-append-tours',
    loadmore : '.js-list-loadmore',
    cta : '.js-list-cta',
    loader : '.js-loader',
    form : '.js-filter-form',
    change : '.js-filter-change',
  };

  lists = [];
  action = [];
  append = [];
  btnsMore = [];
  loaders = [];
  clickCount: number[] = [];
  clickTypes: ('block' | 'args')[] = [];
  changes = [];
  forms = [];

  constructor() {
    this.http = new HttpService();
    this.lists = [].slice.call(document.querySelectorAll(this.classes.list));

    this.getDatas();
    this.onClickLoadMore();
  }

  getDatas() {
    if (this.lists) { 
      this.lists.forEach((list: HTMLElement, i: number) => {
        this.setData(list, i);
      });
    }
  }

  setData(list: HTMLElement, i: number) {
    this.datas.push({
      page_posts: parseInt(list.dataset.page_posts),
      total_posts: parseInt(list.dataset.total_posts),
      args: JSON.parse(list.dataset.args),
      block: JSON.parse(list.dataset.block)
    });
    this.append[i] = list.querySelector(this.classes.append);
    this.btnsMore[i] = list.querySelector(this.classes.loadmore);
    this.loaders[i] = list.querySelector(this.classes.loader);
    this.changes[i] = [].slice.call(list.querySelectorAll(this.classes.change));

    this.onChangeFilter();
  }

  beforeGetList(i) {
    const offset: number = this.datas[i].page_posts * this.clickCount[i];

    this.btnsMore[i].style.display = 'none';
    this.loaders[i].style.display = 'block';

    if (2 * offset > this.datas[i].total_posts && this.datas[i].total_posts > 0) {
      this.datas[i].args.posts_per_page = this.datas[i].total_posts - offset;
    }

    if (this.clickTypes[i] === 'args') {
      this.datas[i].args.offset = offset;
    }

    if (this.clickTypes[i] === 'block') {
      this.datas[i].block.offset = offset;
    }

    this.getList({
      action: this.actions.list,
      data: {
        args: (this.clickTypes[i] === 'args') ? JSON.stringify(this.datas[i].args) : JSON.stringify(this.datas[i].block),
        type: this.clickTypes[i],
        total: (this.clickTypes[i] === 'args') ? this.datas[i].total_posts : this.datas[i].block.number_of_results,
      }
    }, i);
  }

  getList(data: { action: string, data: Record<string, unknown> }, i: number) {
    this.http.request("post", data).
      then((res) => {
        const data: Response = JSON.parse(res.data);
        this.append[i].insertAdjacentHTML('beforeend', data.html);
        this.loaders[i].style.display = 'none';
        if (data.more) {
          this.btnsMore[i].style.display = null;
        } else {
          const cta = this.lists[i].querySelector(this.classes.cta);
          if (cta) {
            cta.style.display = null;
          }
        }
        const index = this.lists.length - 1;
        this.setChangeFilter(this.changes[index], index);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeFilter() {
    if (this.changes) {
      this.changes.forEach((items, i: number) => {
        this.setChangeFilter(items, i);
      });
    }
  }

  setChangeFilter(items, i: number) {
    if (items) {
      items.forEach((item: any) => {
        if (item.checked) {
          item.parentElement.classList.add('active');
        } else {
          item.parentElement.classList.remove('active');
        }
        item.addEventListener('change', () => {
          if (item.checked) {
            item.parentElement.classList.add('active');
          } else {
            item.parentElement.classList.remove('active');
          }
          this.changeFilterEvent(item, items, i);
          this.beforeGetList(i);
        });
      });
    }
  }

  changeFilterEvent(item, items, i) {
    const list = this.lists[i];
    const form = list.querySelector('form');
    this.append[i].innerHTML = '';

    const cta: HTMLElement = list.querySelector(this.classes.cta);

    if (cta) {
      cta.style.display = 'none';
    }
    this.clickCount[i] = 0;
    this.clickTypes[i] = 'block';

    this.setDefaultFilter(item, items, i);
    
    // this.resetBtns[i].forEach((btn: HTMLButtonElement) => btn.style.display = null);
  }

  setDefaultFilter(item, items, i) {
    this.datas[i].block[item.name] = item.value;
  }

  onClickLoadMore() {
    if (this.btnsMore) {
      this.btnsMore.forEach((btn: HTMLButtonElement, i: number) => {
        this.setClickLoadMore(btn, i);
      });
    }
  }

  setClickLoadMore(btn: HTMLButtonElement, i:number) {
    this.clickTypes[i] = 'args';
    this.clickCount[i] = 0;

    if (btn) {
      btn.addEventListener('click', () => {
        this.clickCount[i]++;
        this.beforeGetList(i)
      })
    }
  }
}

new listTours();